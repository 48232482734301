import "../styles/globals.css";
import "../styles/typography.css";
import "@rainbow-me/rainbowkit/styles.css";
import type { AppProps } from "next/app";
import splitbee from "@splitbee/web";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { CustomAvatar } from "../components/Avatar/Avatar";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import Layout from "../components/Layouts/default";
import { Scrollbar } from "../components/Scrollbar/Scrollbar";

import { ReactElement, ReactNode, useEffect } from "react";
import { NextPage } from "next";
import User from "../contexts/User";
import { RainbowTheme } from "../styles/rainbowTheme";
import Script from "next/script";
import { base } from "viem/chains";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// hardhat.id = 1337;

const { chains, publicClient } = configureChains(
  [mainnet, base],
  [
    alchemyProvider({ apiKey: process.env.ALCHEMY_MAINNET_KEY as string }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Coinage",
  projectId: "3c07bcacc60df8ec9ce8b3ebf53ccc1e",
  chains
});

const client = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout =
    Component.getLayout ||
    (page => {
      return <Layout>{page}</Layout>;
    });

  useEffect(() => {
    splitbee.init({
      scriptUrl: "/bee.js",
      apiUrl: "/_hive",
      token: process.env.NEXT_PUBLIC_SPLITBEE
    });
  }, []);

  return (
    <>
      <WagmiConfig config={client}>
        <Script src="https://www.googletagmanager.com/gtag/js?id=UA-245361658-1" />
        <Script
          id="google-tag-manager"
          dangerouslySetInnerHTML={{
            __html: `
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-245361658-1');
      `
          }}
        />

        <Script
          id="fb"
          dangerouslySetInnerHTML={{
            __html: `
        !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1143702962923094');
fbq('track', 'PageView');
      `
          }}
        />
        <Script src="/twt.js" strategy="lazyOnload" />
        <Scrollbar />
        <RainbowKitProvider
          chains={chains}
          theme={RainbowTheme}
          avatar={CustomAvatar}>
          <User>{getLayout(<Component {...pageProps} />)}</User>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
};

export default App;
